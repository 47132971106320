import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import queryString from "query-string"

import VerifyAccount from "../../components/account/verify/verify"

const Verify = ({ location }) => {
  const token = queryString.parse(location.search).token || null

  return (
    <Layout location={location} title={""}>
      <SEO title="Verify Account" lang="ar" />

      {/* Create component for handling request */}

      <VerifyAccount token={token} />
    </Layout>
  )
}

export default Verify
