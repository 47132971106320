import React, { useEffect, useState } from 'react'

const axios = require('axios')
const API = process.env.GATSBY_API_URL || null

const VerifyAccount = ({ token }) => {
  const [isVerified, setIsVerified] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(null)

  useEffect(() => {
    setIsFetching(true)
    axios
      .get(`${API}/verifyEmail/${token}`)
      .then((response) => {
        if (response.data.success) {
          setIsFetching(false)
          setIsVerified(true)
          window.dataLayer.push({
            event: 'asEvent',
            analyticsEventLabel: 'User Account Verification',
            eventCategory: 'User',
            eventAction: 'Account confirmed'
          })
        } else {
          setIsFetching(false)
          setIsError('Unable to verify')
          window.dataLayer.push({
            event: 'asEvent',
            analyticsEventLabel: 'User Account Verification',
            eventCategory: 'User',
            eventAction: 'Account not confirmed'
          })
        }
      })
      .catch((error) => {
        setIsFetching(false)
        setIsError('Unable to verify')
        console.info({ error })
        window.dataLayer.push({
          event: 'asEvent',
          analyticsEventLabel: 'User Account Verification',
          eventCategory: 'User',
          eventAction: 'Account not confirmed'
        })
      })
  }, [])

  return (
    <div>
      {isVerified && <p>Thank you. Your account is now verified.</p>}
      {isFetching && <p>Verifying. Please wait.</p>}
      {isError && <p>Error: {isError}</p>}
    </div>
  )
}
export default VerifyAccount
